<template>
  <b-modal
    ref="modal"
    title="Thêm tài khoản bank Pay Out"
    centered
    ok-title="Thêm mới"
    size="lg"
    :hide-footer="true"
  >
    <validation-observer
      ref="createAccountForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 ml-3 mr-3"
        @submit.prevent="createBankAccount"
      >
        <b-row cols="1">
          <b-col cols="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ngân hàng"
                vid="bankAccount.bankMasterId"
                rules="required"
              >
                <v-select
                  id="bank-master"
                  v-model="bankAccount.bankMasterId"
                  :options="bankMasterOptions"
                  :reduce="bank => bank.id"
                  label="bankName"
                  placeholder="Lựa chọn ngân hàng"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Số tài khoản"
                vid="bankAccount.accountNumber"
                rules="required"
              >
                <b-form-input
                  id="accountNumber"
                  v-model="bankAccount.accountNumber"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Số tài khoản"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tên tài khoản"
                vid="bankAccount.name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="bankAccount.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Tên tài khoản"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu"
                vid="bankAccount.password"
                rules="required"
              >
                <b-form-input
                  id="password"
                  v-model="bankAccount.password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Mật khẩu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Hạn mức chuyển tiền trong ngày"
                vid="bankAccount.moneyLimitPerDay"
                rules="required"
              >
                <b-form-input
                  id="moneyLimitPerDay"
                  v-model="bankAccount.moneyLimitPerDay"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Hạn mức chuyển tiền trong ngày"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Hạn mức chuyển tiền trên một lần"
                vid="bankAccount.moneyLimitToParent"
                rules="required"
              >
                <b-form-input
                  id="moneyLimitToParent"
                  v-model="bankAccount.moneyLimitToParent"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Hạn mức chuyển tiền trên một lần"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Số dư hiện tại"
                vid="bankAccount.balancer"
                rules="required"
              >
                <b-form-input
                  id="balancer"
                  v-model="bankAccount.balancer"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Số dư hiện tại"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="otp"
                vid="bankAccount.otp"
                rules="required"
              >
                <b-form-input
                  id="otp"
                  v-model="bankAccount.otp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="OTP"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="closeModal"
            >
              {{ isUpdate ? 'Huỷ cập nhật' : 'Hủy thêm mới' }}
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid || isCreating"
              class="ml-1"
            >
              <b-spinner
                v-if="isCreating"
                small
              />
              {{ isUpdate ? 'Cập nhật' : 'Thêm mới' }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { required } from '@validations'
import Swal from 'sweetalert2'
import { useBankAccountCreate, useInputImageRenderer } from './useBank'

export default {
  name: 'CreatePayoutAccountModal',
  components: {
    vSelect,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const modal = ref(null)
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const isCreating = ref(false)
    const isUpdate = ref(false)

    const showModal = () => {
      modal.value.show()
    }
    const closeModal = () => {
      modal.value.hide()
    }

    const {
      bankMaster,
      bankMasterOptions,
      bankAccount,
      fetchBankMaster,
    } = useBankAccountCreate()

    const updateAccount = account => {
      isUpdate.value = true
      bankAccount.value = account
      showModal()
    }

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, bankAccount)

    const initBankMasterOptions = () => {
      fetchBankMaster().then(res => {
        bankMasterOptions.value = res
      })
    }

    const createBankAccount = () => {
      isCreating.value = true
      Swal.fire(
        'Thất bại!',
        'Thêm tài khoản thất bại!',
        'error',
      )
    }

    onMounted(() => {
      initBankMasterOptions()
    })

    return {
      modal,
      showModal,
      bankMaster,
      bankMasterOptions,
      bankAccount,
      createBankAccount,
      closeModal,
      refInputEl,
      previewEl,
      inputImageRenderer,
      isCreating,
      isUpdate,
      updateAccount,

      required,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
